<template>
  <div>
    <div class="c-index-menu_list">
      <div
        v-for="(item, index) in menu"
        :key="index"
        class="c-index-menu_list_item"
        @click="toMenu(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
  },

  computed: {
    ...mapState({
      menu: state => state.login.menuList
    })
  },

  methods: {
    toMenu(item) {
      if (item.url && item.url !== '#') {
        this.$toPage(item.url)
      } else {
        if (item.children && item.children[0] && item.children[0].url) {
          this.$toPage(item.children[0].url)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.c-index-menu_list {
  display: flex;
  flex-wrap: wrap;
  .c-index-menu_list_item {
    width: 32%;
    border: 1px solid #e6e6e6;
    text-align: center;
    line-height: 120px;
    box-shadow: 0 2px 6px #e6e6e6;
    cursor: pointer;
    margin-right: 2%;
    margin-bottom: 20px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:hover {
      border-color: #1890ff;
    }
  }
}
</style>
